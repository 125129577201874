import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GuidesDestinyCountryService {

  configService: ConfigService;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  getGuides() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllGuidesInDestinyCountry'), { headers });
  }

  getDispatchGuides() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getGuidesToDispatchInDestinyCountry'), { headers });
  }

  getListCarriersCountry() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/listCarriersCountry'), { headers });
  }

  getExcelDocument(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams()
    .set('carrier_id', data['id'])
    .set('guides', JSON.stringify(data['guides'])); 
    return this.http.get(this.configService.serverIp.concat('/exportCarrierDocument'), { headers, params});
  }
}
