import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ConfigService} from './config.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersAgencyService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getAgencyUsers(id): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('agency_id', id);
    return this.http.get(this.configService.serverIp.concat('/listUsersOfAgency'), {headers, params});
  }

  createAgencyUser(user) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addUserToAgency'), user, {headers});
  }
}
