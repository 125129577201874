import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CreateServiceService {

  configService: ConfigService;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  postCreateService(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createService'), data, {headers});
  }

  getLastCode(){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getLastCode'),{headers});
  }

  getCarriers(id){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('country_id', id);
    return this.http.get(this.configService.serverIp.concat('/getCarriersName'),{headers, params});
  }
}
