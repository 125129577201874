import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {a} from '@angular/core/src/render3';

@Injectable({
  providedIn: 'root'
})
export class ListShopsService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getShops(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/listShops'), {headers});
  }

  getShop(shopId: string): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('id_shop', shopId);
    return this.http.get(this.configService.serverIp.concat('/getShop'), {headers, params});
  }

  editShop(shop): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      id_shop: shop.shop_id,
      shopname: shop.shopName
    };
    return this.http.post(this.configService.serverIp.concat('/editShop'), data, {headers});
  }

  deleteShop(shop): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/deleteShop'), shop, {headers});
  }

}
