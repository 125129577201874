import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

   
  requestQuote(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/requestQuote'), data, { headers });
  }


  getAllrequestQuote(skip){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('skip', skip);
    return this.http.get(this.configService.serverIp.concat('/getAllrequestQuote'), { headers, params });
   }

   getAllUserRequestQuote(skip){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('skip', skip);
    return this.http.get(this.configService.serverIp.concat('/getAllUserRequestQuote'), { headers, params });
   }

   getRequestQuote(quotation){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('quotation', quotation);
    return this.http.get(this.configService.serverIp.concat('/getRequestQuote'), { headers, params });
   }

   
  approveQuote(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/approveQuote'), data, { headers });
  }


  rejectQuote(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/rejectQuote'), data, { headers });
  }

  approvePayment(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/approvePaymentQuote'), data, { headers });
  }

  addTrack(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addTrackToQuotationProduct'), data, { headers });
  }

 
}
