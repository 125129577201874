import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  configService: ConfigService;
  constructor(private http: HttpClient, ) {
    this.configService = new ConfigService();
  }

  getCompaniesToSendGuides() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getEnterprisesToDeliveryGuide'), { headers });
  }

  getAllEnterprises(){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllEnterprises'), { headers });
  }

  getServicesByEnterprise(id: any){
    
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('enterprise_id', id);
    return this.http.get(this.configService.serverIp.concat('/getServicesOfEnterprise'), { headers, params });
    
  }

  assignServiceToEnterprise(enterprise_id, services) {
    const data = {
      'enterprise_id': enterprise_id,
      'services': services
    };
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/assignServiceToEnterprise'), data, { headers });
  }

  editServiceByEnterprise(service) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/editServiceOfEnterprise'), service, { headers });
  }
  
}
