import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdministrativeReportService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getData(date1, date2) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('date1', date1).set('date2', date2);
    return this.http.get(this.configService.serverIp.concat('/statisticsFromAllAgencies'), { headers, params });
  }

  getDataAgency(date1, date2, id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('date1', date1).set('date2', date2).set('agency_id', id);
    return this.http.get(this.configService.serverIp.concat('/statisticsOfAgency'), { headers, params });
  }

}
