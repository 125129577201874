import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DispatchShipmentService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getShipments(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/dispatchShipping'), {headers});
  }

  postDispatchShipments(shipments) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'ids_shipping': shipments
    };
    return this.http.post(this.configService.serverIp.concat('/dispatchShippings'), data, {headers});
  }

  // ********** TO DESTINATION CITY *************

  getShipmentsReadyToDispatchToCity(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/readyToDispatchToCity'), {headers});
  }

  postDispatchShipmentsToCity(shipments) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'ids_shipping': shipments
    };
    return this.http.post(this.configService.serverIp.concat('/dispatchToCity'), data, {headers});
  }

}
