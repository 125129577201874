import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplyMovementsService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  applyPackagingToPackage(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/applyPackagingToPackage'), data, { headers });
  }

  listPackageMovements(package_id, isWRPackage, packages: any = null) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = packages != null ? new HttpParams()
      .set('packages', packages) : new HttpParams().set('package_id', package_id).set('isWRPackage', isWRPackage);
    return this.http.get(this.configService.serverIp.concat('/listPackageMovements'), { headers, params });
  }
}
