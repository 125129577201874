import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class PackageStatusService {

  private status = {
    '1': {
      'es': 'Paquete en bodega',
      'en': 'Package in warehouse'
    },
    '2': {
      'es': 'Solicitado para envío',
      'en': 'Shipment requested'
    },
    '3': {
      'es': 'Despachado a ciudad destino',
      'en': 'Dispatched to destination city'
    },
    '4': {
      'es': 'En ciudad destino',
      'en': 'In destination city'
    },
    '5': {
      'es': 'Pendiente de revisión',
      'en': 'Pending revision'
    },
    '6': {
      'es': 'Pendiente en Aduana',
      'en': 'Pending Customs'
    },
    '7': {
      'es': 'Pendiente confimarción de pago',
      'en': 'Pending payment confirmation'
    },
    '8': {
      'es': 'Acuerdo de pago',
      'en': 'Payment agreement'
    },
    '9': {
      'es': 'Solicitud de envío rechazada',
      'en': 'Rejected shipment request'
    }
  }

  private movements = {
    '1': {
      'es': 'Cambio de almacén',
      'en': 'Storehouse change'
    },
    '2': {
      'es': 'Cambio de WR',
      'en': 'WR Change'
    },
    '3': {
      'es': 'Cambio de Rack',
      'en': 'Rack change'
    },
    '4': {
      'es': 'Modificación de contenidos',
      'en': 'Content modification'
    },
    '5': {
      'es': 'Aplicación de embalaje',
      'en': 'Packaging application'
    },
    '6': {
      'es': 'Marcado como perdido',
      'en': 'Marked as lost'
    },
    '7': {
      'es': 'Cambio de dueño',
      'en': 'Owner change'
    }
  }

  constructor() { }

  public getStatus(language, statusId) {
    return this.status[statusId][language];
  }

  public getMovement(language, movementId) {
    return this.movements[movementId][language];
  }
}
