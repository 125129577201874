import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DollarValueService {

  configService = null;

  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
  }

  getValue(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/getOrCreateDollarValueForToday'), {'country_id': data}, {headers});
  }

  updateDollarPrice(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/updateDollarValue'), data, {headers});
  }

  getValueToday(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/updateDollarValueForToday'), {'id': data}, {headers});
  }

  getPricesDollar(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('country_id', data);
    return this.httpClient.get(this.configService.serverIp.concat('/getAllDollarValuesOfCountry'), {headers, params});
  }

  getDollarValueCountryDate(country_id, date) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams()
      .set('country_id', country_id)
      .set('date', date);
    return this.httpClient.get(this.configService.serverIp.concat('/getDollarValueOfCountryInDate'), {headers, params});
  }
}
