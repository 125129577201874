import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuideDestinyCountryService {

  configService = null;

  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
   }

   registerGuide(data){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/registerGuideInDestinyCountry'),data, {headers});
   }

   getGuidesToCharge(activeTab,skip){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token

    });
    const params = new HttpParams().set('index', activeTab).set('skip', skip);
    return this.httpClient.get(this.configService.serverIp.concat('/getGuidesToChargeInDestinyCountry'), {headers,params});
   }

  assignLocalCarrierToGuide(guide,carrierLocal_id){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/assignLocalCarrierToGuide'),{
      "guide" : guide,
      "carrierLocal_id" : carrierLocal_id
    } ,{headers});
  }

   getAllCarrierLocal(){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.get(this.configService.serverIp.concat('/getAllCarrierLocalDestinetionCountry'), {headers});
   }


   paymentGuide(price,guide){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/payGuideInDestinyCountry'),{
      "price": price,
      "guide":guide
    }, {headers});
   }

   paymentGuide2(data){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/payGuideInDestinyCountry'),data, {headers});
   }

    requiredFiledsCarrier(data){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/requiredFiledsCarrier'),JSON.stringify(data), {headers});
   }

   /**
    * Servicio Frontend para busqueda de los datos de direccion de una guia de acuerdo al id de la guia
    * @param guideId
    */
   searchAddressByGuide(guideId){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/searchAddressByGuide'),{
      "guideId":guideId
    }, {headers});
   }

   guidePendingPayment(guide){
    console.log("invoco el servicio");
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/guidePendingPayment'),{
      "guide":guide
    }, {headers});
   }

}
