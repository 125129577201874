import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorehouseService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  createStoreHouse(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/addStorehouse'), data, { headers });
  }

  listStorehouses(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/listStorehouse'), { headers });
  }

  getStorehouseById(id): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = {
      storehouse_id: id
    };
    return this.http.get(this.configService.serverIp.concat('/getStorehouseById'), { headers, params });
  }

  editStorehouse(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/editStorehouse'), data, { headers });
  }
}
