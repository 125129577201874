import { Injectable } from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PendingCodPaymentService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getCODShipments(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/pendingCodShipping'), {headers});
  }


  getAllCODShipments(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/allPendingCodShipping'), {headers});
  }

  markCODPaymentAsPaid(data):Observable<any>{
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/markCODPaymentAsPaid'), data, {headers});

  }



}
