import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class PrealertReportService {
  configService: ConfigService;
  constructor(private httpClient: HttpClient) {
    this.configService = new ConfigService();
  }

  getPrealertReport(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/PreAlertReport'), data, { headers });
  }

  getPrealertByTracking(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.httpClient.post(this.configService.serverIp.concat('/PreAlertReportByTracking'), data, { headers });
  }

  contentPrealert(tracking) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    let data = {
      tracking: tracking
    }
    return this.httpClient.post(this.configService.serverIp.concat('/contentPrealert'), data, { headers });
  }


  /**
   *
   * @param id id de la pre alerta a la cual se desea eliminar
   * @param value  valor de justificacion de l aprealerta
   * @returns
   */
  desactivePreAlert(id,value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    let data = {
      id: id,
      justification:value
    }
    return this.httpClient.post(this.configService.serverIp.concat('/desactivePreAlert'), data, { headers });
  }

  /**
   * 
   * @param tracking used for search information about prealert
   */
  getInfoPrealert(tracking){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    let data = {
      tracking: tracking
    }
    return this.httpClient.post(this.configService.serverIp.concat('/getInfoPrealert'), data, { headers });
  }
}
